export const categories = [
  { label: "ID document", value: "id_document" },
  { label: "Application fee", value: "application_fee" },
  { label: "Proof of income (Payslips)", value: "payslip" },
  { label: "Bank statements", value: "bank_statement" },
  { label: "Signed Lease", value: "lease_signed" },
  { label: "Profile Picture", value: "profile_picture" }
];

export const pricing = [
  { plan: "Basic Plan", price: "99" },
  { plan: "Premium Plan", price: "199" },
  { plan: "Premium Plus Plan", price: "250" }
];

export const PROPERTY_STATUS = [
  { name: "Vacant", value: "vacant" },
  { name: "Occupied", value: "occupied" },
  { name: "Under maintenance", value: "under_maintenance" },
  { name: "Closed", value: "closed" },
  { name: "Sold", value: "sold" }
];

export const rentalStatus = [
  { name: "New", value: "new" },
  { name: "Processing", value: "processing" },
  { name: "Waiting Documents", value: "waiting_documents" },
  { name: "Active", value: "active" },
  { name: "Approved", value: "approved" },
  { name: "Rent Due", value: "rent_due" },
  { name: "Expired", value: "expired" },
  { name: "Balance Due", value: "balance_due" },
  { name: "Eviction", value: "eviction" }
];

export const USER_STATUS = [
  { name: "New", value: "new" },
  { name: "Active", value: "active" },
  { name: "Suspended", value: "suspended" },
  { name: "Archived", value: "archived" }
];

export const MAINTENANCE_STATUS = [
  { name: "New", value: "new" },
  { name: "In progress", value: "in_progress" },
  { name: "Resolved", value: "resolved" },
  { name: "Closed", value: "closed" }
];

export const REQUEST_DEMO_STATUS = [
  { name: "New", value: "new" },
  { name: "Contacted", value: "contacted" },
  { name: "Demo Scheduled", value: "demo-scheduled" },
  { name: "Negotiation", value: "negotiation" },
  { name: "Follow-Up", value: "follow-up" },
  { name: "Closed - Won", value: "won" },
  { name: "Closed - Lost", value: "lost" }
];

export const SUPPORT_STATUS = [
  { name: "New", value: "new" },
  { name: "In progress", value: "in_progress" },
  { name: "Awaiting feedback", value: "awaiting-feedback" },
  { name: "Follow-Up", value: "follow-up" },
  { name: "Resolved", value: "resolved" },
  { name: "Closed", value: "closed" }
];

export const INVOICE_STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Paid", value: "paid" },
  { name: "Failed", value: "failed" },
  { name: "Overdue", value: "overdue" },
  { name: "Cancelled", value: "cancelled" }
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const ADMIN_ROLES = [
  {
    label: "Super Admin",
    role: "super-admin",
    value: 1
  },
  {
    label: "Admin",
    role: "admin",
    value: 2
  }
];

export const ROLES = [
  {
    label: "Super Admin",
    role: "super-admin",
    value: 1
  },
  {
    label: "Admin",
    role: "admin",
    value: 2
  },
  {
    label: "Account Admin",
    role: "account-admin",
    value: 3
  },
  {
    label: "Account User",
    role: "account-user",
    value: 4
  },
  {
    label: "Tenant",
    role: "tenant",
    value: 5
  }
];

// Plans

export const PLAN_DETAILS = [
  {
    id: 1,
    plan: "standard",
    plan_code: "",
    price: 0,
    value: [
      {
        feature: "property-groups",
        limit: 1
      },
      {
        feature: "properties",
        limit: 5
      },
      {
        feature: "account-users",
        limit: 1
      }
    ]
  },
  {
    id: 2,
    plan: "premium",
    price: 0,
    plan_code: "PLN_opoc8i609by0rw2",
    value: [
      {
        feature: "property-groups",
        limit: 5
      },
      {
        feature: "properties",
        limit: 30
      },
      {
        feature: "account-users",
        limit: 5
      }
    ]
  },
  {
    id: 3,
    plan: "enterprise",
    price: 0,
    plan_code: "PLN_jnnlqxrurplv5vf",
    value: [
      {
        feature: "property-groups",
        limit: -1
      },
      {
        feature: "properties",
        limit: -1
      },
      {
        feature: "account-users",
        limit: -1
      }
    ]
  }
];

export const PLANS = [
  { name: "Standard", value: 1 },
  { name: "Premium", value: 2 },
  { name: "Enterprise", value: 3 },
  { name: "Enterprise Plus", value: 4 }
];

export const PRIORITY = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
  { name: "Urgent", value: "urgent" }
];

export const VIEWEDBY = [
  { name: "Landlord only", value: "landlord-only" },
  { name: "Landlord and tenant", value: "landlord-tenant" }
];

export const PROPERTY_TYPE = [
  { name: "Apartment", value: "apartment" },
  { name: "House", value: "house" },
  { name: "Other", value: "other" }
];

export const ENTITY_TYPE = [
  { name: "Property", value: "property" },
  { name: "Property Group", value: "property_group" },
  { name: "Landlords/Admins/Account Users/Tenants", value: "user" }
];

export const EVENT_ACTION = [
  { name: "Created", value: "create" },
  { name: "Updated", value: "update" }
];
