import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import PublicSupport from "./pages/public/PublicSupportPage";
const InvoiceView = lazy(() => import("./pages/Billing/InvoiceView"));
const PropertiesReport = lazy(() => import("./pages/Reports/ReportsItems/PropertiesReport"));
const Loader = lazy(() => import("./components/Loader/Loader"));
const GuidePage = lazy(() => import("./pages/public/resources/Guides/GuidePage"));
const BlogDetailsPage = lazy(() => import("./pages/public/resources/Blog/BlogDetailsPage"));
const BillingPage = lazy(() => import("./pages/Billing/BillingPage"));
const Index = lazy(() => import("./pages/public"));
const Login = lazy(() => import("./pages/Auth/Login"));
const Register = lazy(() => import("./pages/Auth/Register"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Properties = lazy(() => import("./pages/Properties/Properties"));
const UnauthorizedAccessPage = lazy(() => import("./pages/Auth/UnauthorisedAccessPage"));
const PageNotExists = lazy(() => import("./pages/Auth/PageNotExists"));
const PropertyGroupPage = lazy(() => import("./pages/Properties/PropertyGroupPage"));
const TestMode = lazy(() => import("./pages/TestMode"));
const RentalApplications = lazy(() => import("./pages/RentalApplications/RentalApplications"));
const Landlord = lazy(() => import("./pages/Landlord/Landlord"));
const Rentals = lazy(() => import("./pages/Rentals/Rentals"));
const Tenant = lazy(() => import("./pages/Tenant/Tenant"));
const RentalApplication = lazy(() => import("./pages/RentalApplications/RentalApplicationForm"));
const RentalApplicationDetails = lazy(() => import("./pages/RentalApplications/RentalApplicationDetails"));
const MyProfile = lazy(() => import("./pages/User/MyProfile"));
const Settings = lazy(() => import("./pages/User/Settings"));
const Billing = lazy(() => import("./pages/Billing/Billing"));
const Pricing = lazy(() => import("./pages/public/PricingPage"));
const FileViewerPage = lazy(() => import("./pages/FileViewerPage"));
const DocumentsPage = lazy(() => import("./pages/Documents/DocumentsPage"));
const NewRental = lazy(() => import("./pages/Rentals/NewRental"));
const Maintenance = lazy(() => import("./pages/Maintenance/Maintenance"));
const AccountUsersPage = lazy(() => import("./pages/Accounts/AccountUsersPage"));
const EventLogsPage = lazy(() => import("./pages/EventLogs/EventLogsPage"));
const MaintenanceView = lazy(() => import("./pages/Maintenance/MaintenanceView"));
const Roles = lazy(() => import("./pages/Roles/Roles"));
const RolesPermissions = lazy(() => import("./pages/Roles/RolesPermissions"));
const Company = lazy(() => import("./pages/public/company"));
const RequestDemo = lazy(() => import("./pages/public/RequestDemo"));
const Leads = lazy(() => import("./pages/Leads/Leads"));
// const Plans = lazy(() => import("./pages/admin/Plans"));
const Notifications = lazy(() => import("./pages/Notifications/Notifications"));
const TenantsPortal = lazy(() => import("./pages/public/Features/TenantsPortal"));
const PropertyMaintenance = lazy(() => import("./pages/public/Features/PropertyMaintenance"));
const LandlordPortal = lazy(() => import("./pages/public/Features/LandlordPortal"));
const PropertyReports = lazy(() => import("./pages/public/Features/PropertyReports"));
const TenantsScreening = lazy(() => import("./pages/public/Features/TenantsScreening"));
const RentalApplicationsFeature = lazy(() => import("./pages/public/Features/RentalApplicationsFeature"));
const PropertyManagement = lazy(() => import("./pages/public/Features/PropertyManagement"));
const ContactUs = lazy(() => import("./pages/public/ContactUs"));
const EmailConfirmation = lazy(() => import("./pages/Auth/EmailConfirmation"));
const Reports = lazy(() => import("./pages/Reports/Reports"));
const RegisterSuccessfulPage = lazy(() => import("./pages/Auth/RegisterSuccessfulPage"));
const ResetPasswordEmail = lazy(() => import("./pages/Auth/ResetPasswordEmail"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const Privacy = lazy(() => import("./pages/public/legal/Privacy"));
const Terms = lazy(() => import("./pages/public/legal/Terms"));
const Blog = lazy(() => import("./pages/public/resources/Blog/Blog"));
const DemoVideo = lazy(() => import("./pages/public/resources/DemoVideo"));
const Guides = lazy(() => import("./pages/public/resources/Guides/Guides"));
const ResourcesSupport = lazy(() => import("./pages/public/resources/Support"));
const Support = lazy(() => import("./pages/Leads/Support"));
const SupportView = lazy(() => import("./pages/Leads/SupportView"));
const Partners = lazy(() => import("./pages/public/Partners"));
const Faqs = lazy(() => import("./pages/public/Faqs"));
const Career = lazy(() => import("./pages/public/Career"));
const AdminUsers = lazy(() => import("./pages/admin/AdminUsers"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/ScrollToTop"));
const Payment = lazy(() => import("./pages/Payments/Payment"));
const ChoosePackage = lazy(() => import("./pages/Payments/ChoosePackage"));

interface IRoute {
  path: string;
  component: React.ComponentType<any>;
  isAuthenticated?: boolean;
}

const routes: IRoute[] = [
  /* UI */
  {
    path: "/",
    component: Index
  },
  {
    path: "/pricing",
    component: Pricing
  },
  {
    path: "/company",
    component: Company
  },
  {
    path: "/request-demo",
    component: RequestDemo
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/register-successful",
    component: RegisterSuccessfulPage
  },
  {
    path: "/my-profile",
    component: MyProfile
  },
  {
    path: "/settings",
    component: Settings
  },
  {
    path: "/features/tenants-portal",
    component: TenantsPortal
  },
  {
    path: "/features/property-maintenance",
    component: PropertyMaintenance
  },
  {
    path: "/features/landlord-portal",
    component: LandlordPortal
  },
  {
    path: "/features/property-reports",
    component: PropertyReports
  },
  {
    path: "/features/tenants-screening",
    component: TenantsScreening
  },
  {
    path: "/features/rental-application",
    component: RentalApplicationsFeature
  },
  {
    path: "/features/properties-management",
    component: PropertyManagement
  },
  {
    path: "/resources/blog",
    component: Blog
  },
  {
    path: "/resources/blog/:slug",
    component: BlogDetailsPage
  },
  {
    path: "/resources/guides",
    component: Guides
  },
  {
    path: "/resources/guides/:slug",
    component: GuidePage
  },
  {
    path: "/resources/demo-video",
    component: DemoVideo
  },
  {
    path: "/resources/support",
    component: ResourcesSupport
  },
  {
    path: "/submit/support",
    component: PublicSupport
  },
  {
    path: "/partners",
    component: Partners
  },
  {
    path: "/faqs",
    component: Faqs
  },
  {
    path: "/career",
    component: Career
  },
  {
    path: "/contact",
    component: ContactUs
  },
  {
    path: "/verify/:token",
    component: EmailConfirmation
  },
  {
    path: "/reset-password-email",
    component: ResetPasswordEmail
  },
  {
    path: "/reset_password/:token/:id",
    component: ResetPassword
  },
  {
    path: "/legal/privacy",
    component: Privacy
  },
  {
    path: "/legal/terms-conditions",
    component: Terms
  },

  /* Admin */
  {
    path: "/test-mode",
    component: TestMode
  },
  {
    path: "/landlords",
    component: Landlord
  },
  {
    path: "/roles",
    component: Roles
  },
  {
    path: "/role-permissions/:role_id",
    component: RolesPermissions
  },
  {
    path: "/leads",
    component: Leads
  },
  {
    path: "/support",
    component: Support
  },
  {
    path: "/support/view/:id",
    component: SupportView
  },
  {
    path: "/billing",
    component: BillingPage
  },
  {
    path: "/invoices/:invoice_id",
    component: InvoiceView
  },

  /* Admin & Landlord */
  {
    path: "/admin-users",
    component: AdminUsers
  },
  {
    path: "/account-users",
    component: AccountUsersPage
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/billing",
    component: Billing
  },
  {
    path: "/property-group",
    component: PropertyGroupPage
  },
  {
    path: "/properties",
    component: Properties
  },
  {
    path: "/rental-applications",
    component: RentalApplications
  },
  {
    path: "/rentals",
    component: Rentals
  },
  {
    path: "/tenants",
    component: Tenant
  },
  {
    path: "/unauthorized",
    component: UnauthorizedAccessPage
  },
  {
    path: "/event-logs",
    component: EventLogsPage
  },
  {
    path: "/reports",
    component: Reports
  },
  {
    path: "/reports/properties-report",
    component: PropertiesReport
  },

  /* Landlord and Tenant */

  {
    path: "/documents",
    component: DocumentsPage
  },
  {
    path: "/notifications",
    component: Notifications
  },
  {
    path: "/maintenance",
    component: Maintenance
  },
  {
    path: "/new-rental/:rental_application_id",
    component: NewRental
  },
  {
    //path: "/new-maintenance/:rental_id",
    path: "/maintenance/:id",
    component: MaintenanceView
  },
  /* Tenant */
  {
    path: "/rental-application/:property_id",
    component: RentalApplication
  },
  {
    path: "/rental-application/:id/:user_id",
    component: RentalApplicationDetails
  },
  {
    path: "/view-file/:file_id",
    component: FileViewerPage
  },
  /* PAYMENTS */
  {
    path: "/payment",
    component: Payment
  },
  { path: "/plans-upgrade", component: ChoosePackage }
];

function Routers() {
  function pageLoader() {
    return (
      <div className="flex justify-center items-center my-8">
        <Loader />
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={pageLoader()}>
        <Routes>
          {routes.map((route) => {
            const Component: React.ComponentType<any> = route.component;
            return <Route key={route.path} path={route.path} element={<Component />} />;
          })}
          {/* Outlet is used to render nested routes */}
          <Route
            path="*" // A wildcard route to catch any unmatched paths
            element={<PageNotExists />} // Use Outlet to render nested routes
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default Routers;
