interface IProps {
  noPadding?: boolean;
  noCenter?: boolean;
}
function Loader(props: IProps) {
  const { noPadding, noCenter } = props;

  // function renderCustomLoader() {
  //
  // }

  function render() {
    return (
      <div className={"flex justify-center " + (noPadding ? "" : "p-10") + (noCenter ? "" : "items-center")}>
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }
  return render();
}

export default Loader;
