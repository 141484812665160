import Message from "../components/Message/Message";
import { MONTHS, PLAN_DETAILS } from "./constants";

import config from "../config";
import Toast from "../components/Toast/Toast";
import React from "react";
import moment, { Moment } from "moment";

function generateErrorMessage(error: any, extraMessage: string = "") {
  return error && error.message ? <Message message={error.message + extraMessage} type="error" icon="fa-exclamation" /> : null;
}

function getError(error: any, extraMessage: string = "") {
  return error && error.message ? error.message + extraMessage : null;
}

function generateMessage(message: any, type: string) {
  return message ? <Message message={message} type={type} /> : null;
}

function isUserAccountAdmin(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser?.role?.role === "account-admin";
  } else {
    return store?.user?.role?.role === "account-admin";
  }
}

function isUserAccountUser(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser?.role?.role === "account-user";
  } else {
    return store?.user?.role?.role === "account-user";
  }
}

function isUserTenant(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser?.role?.role === "tenant";
  } else {
    return store?.user?.role?.role === "tenant";
  }
}

function isUserAdmin(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser?.role?.role === "admin";
  } else {
    return store?.user.role?.role === "admin";
  }
}

function isUserSuperAdmin(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser?.role?.role === "super-admin";
  } else {
    return store?.user.role?.role === "super-admin";
  }
}

function isUserSuperAdminOrAdmin(store: any) {
  return isUserSuperAdmin(store) || isUserAdmin(store);
}

function user(store: any) {
  if (store?.impersonatingUser) {
    return store?.impersonatingUser;
  } else {
    return store?.user;
  }
}

function profilePicture(store: any) {
  return store?.profilePicture?.content ?? null;
}

// function unreadNotificationCount(store: any) {
//   return store?.unreadCount?.count || 0;
// }

function humanFormatDateTime(date: string | Date | Moment | undefined, includeSeconds?: boolean): string {
  if (!date) return "";
  const momentDate: Moment = moment(date);
  return momentDate.format("D MMM YYYY HH:mm" + (includeSeconds ? ":ss" : ""));
}

function humanFormatDate(date: string | Date | Moment | undefined): string {
  if (!date) return "";
  const momentDate: Moment = moment(date);
  return momentDate.format("D MMM YYYY");
}

function humanFormatDateWithDay(date: string | Date | Moment | undefined): string {
  if (!date) return "";
  const momentDate: Moment = moment(date);
  return momentDate.format("ddd, D MMM YYYY");
}

function getMonth(monthValue: number) {
  if (monthValue > -1 && monthValue < 13) {
    return MONTHS[monthValue];
  } else {
    return "Invalid month";
  }
}

function getCurrentMonthEndDate() {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  // console.log(lastDay);
  return humanFormatDate(lastDay);
}

function titleCase(str: string) {
  if (str) {
    const splitStr = str?.toLowerCase().split(/[\s-_]+/); // Split by space or hyphen
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  } else {
    return null;
  }
}

function clone(obj: any): any {
  if (!obj) return;
  return JSON.parse(JSON.stringify(obj));
}

function convertArgsToParams(params: any) {
  const paramString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return paramString;
}

const activeRequests: any = {};
let requestIDCounter = 0;

async function killDuplicateRequests(activeRequest: { body: any; method: string; requestID: number; url: string; requestTimeStamp: Date }) {
  let requestIDs: any[] = [];

  if (activeRequests[activeRequest?.url]) {
    requestIDs = Object.keys(activeRequests[activeRequest.url]);
  }

  requestIDs.forEach(async (requestID) => {
    const req = activeRequests[activeRequest.url][requestID];
    if (req.requestID === activeRequest.requestID) {
      // Remove request from active requests
      delete activeRequests[activeRequest.url][requestID];
    } else if (
      activeRequest.url === req.url &&
      activeRequest.method === req.method &&
      JSON.stringify(activeRequest.body) === JSON.stringify(req.body)
    ) {
      // // kill all other requests with the same parameters & remove from active requests
      if (activeRequest.requestTimeStamp < req.requestTimeStamp) {
        // Abort only if request was made before the active request and has not yet returned a response
        await req.abortController.abort();
        // console.warn("Aborted duplicate request", `${req.method}: ${req.url}`);
        delete activeRequests[activeRequest.url][requestID];
      }
    }
    if (Object.keys(activeRequests[activeRequest.url]).length === 0) {
      delete activeRequests[activeRequest.url];
    }
  });
}

async function request(store: any, endpoint: string, method: string, args?: any, headers?: any, appendStringArgs?: any) {
  // const retryCounterValue: number = retryCounter ?? 0;
  // const maxRetries = 3;
  let accessToken: any;
  if (store) {
    accessToken = store?.access_token;
  }
  if (!headers) {
    headers = { "Content-Type": "application/json" };
  }
  method = method.toUpperCase();
  try {
    let body = null;
    let path = endpoint;

    if (method === "GET") {
      if (args) {
        path += "/?" + convertArgsToParams(args) + (appendStringArgs ? `&${appendStringArgs}` : "");
      }
    } else if (args instanceof FormData) {
      // For FormData (file uploads), assign directly without JSON.stringify
      body = args;
    } else {
      body = JSON.stringify(args);
    }

    const url: any = config.baseUrl + path;

    if (accessToken) {
      headers["Authorization"] = "bearer " + accessToken;
    }
    let request: any;

    const init: { method: string; headers: any; body: any; signal?: any } = {
      method: method,
      headers: headers,
      body: body
    };

    if (method === "GET") {
      const requestID = requestIDCounter;
      requestIDCounter++;
      const abortController = new AbortController();
      request = {
        url: url,
        method,
        abortController,
        body,
        requestID,
        requestTimeStamp: new Date()
      };
      if (!activeRequests[url]) {
        activeRequests[url] = {};
      }
      activeRequests[url][requestID] = request;
      init.signal = abortController.signal;
    }

    const response = await fetch(url, init);

    if (method === "GET") {
      killDuplicateRequests(request);
    }

    if (!response.ok) {
      checkTokenValidity(response, endpoint);

      let errorMsg = await response.text();
      try {
        // Parse the response as JSON
        const errorJson = JSON.parse(errorMsg);

        // Check if the `detail` property exists and use it
        if (errorJson.detail) {
          errorMsg = errorJson.detail;
        }
      } catch (e) {
        // If parsing fails, fallback to the original error message
        console.error("Error parsing response:", e);
      }
      // if (response.status === 504 || response.status === 502) {
      //   if (retryCounterValue < maxRetries) {
      //     return new Promise((resolve) => {
      //       setTimeout(
      //         async () => {
      //           const res = await request(store, endpoint, method, args, headers, retryCounterValue + 1);
      //           resolve(res);
      //         },
      //         1000 * (retryCounterValue + 1)
      //       ); // Progressively longer timeouts as retries progress
      //     });
      //   } else {
      //     console.log("Service unavailable, maximum retries reached.");
      //   }
      // }

      return { ok: false, error: new Error(errorMsg), code: response.status };
    }

    let data = "";
    try {
      data = await response.text();
      data = JSON.parse(data);
    } catch (e) {
      // Assume it is text
    }

    const responseHeaders: any = {};

    response.headers.forEach((value, key) => {
      responseHeaders[key] = value;
    });

    return { ok: true, data, code: response.status, headers: responseHeaders };
  } catch (e) {
    return { ok: false, error: getError(e, "") };
  }
}

function checkTokenValidity(response: any, endpoint: string) {
  if ((response.status === 403 || response.status === 401) && endpoint !== "/login") {
    window.location.href = "/login/?unauthorised";
  }
}

function showToast(toastID: string, message: string, type: string) {
  return <Toast toastID={toastID} message={message} type={type} />;
}

function generateID() {
  // Generate a random number between 0 and 99999
  const randomNumber = Math.floor(Math.random() * 100000);
  // Get the current timestamp
  const timestamp = Date.now();
  // Combine the random number and timestamp to create the toast ID
  return `${timestamp}_${randomNumber}`;
}

//PLANS//

function isFreePlan(store: any) {
  if (user(store)?.plan_id) {
    return user(store)?.plan_id === 1;
  } else {
    return true;
  }
}

function isPremiumPlan(store: any) {
  if (user(store)?.plan_id) {
    return user(store)?.plan_id === 2;
  } else {
    return false;
  }
}

function isEnterpriseOrPlusPlan(store: any) {
  if (user(store)?.plan_id) {
    return user(store)?.plan_id === 3 || user(store)?.plan_id === 4;
  } else {
    return false;
  }
}

function hasReachedPropertiesLimit(store: any, totalCount: number) {
  if (isUserSuperAdminOrAdmin(store)) {
    return false;
  }
  let limit = 0;
  if (user(store)?.plan_id) {
    if (isFreePlan(store)) {
      limit = getPlanFeatureLimit("standard", "properties");
      return totalCount >= limit;
    }
    if (isPremiumPlan(store)) {
      limit = getPlanFeatureLimit("premium", "properties");
      return totalCount >= limit;
    }
    return !isEnterpriseOrPlusPlan(store);
  } else {
    return true;
  }
}

function hasReachedPropertyGroupLimit(store: any, totalCount: number) {
  if (isUserSuperAdminOrAdmin(store)) {
    return false;
  }
  let limit = 0;
  if (user(store)?.plan_id) {
    if (isFreePlan(store)) {
      limit = getPlanFeatureLimit("standard", "property-groups");
      return totalCount >= limit;
    }
    if (isPremiumPlan(store)) {
      limit = getPlanFeatureLimit("premium", "property-groups");
      return totalCount >= limit;
    }
    return !isEnterpriseOrPlusPlan(store);
  } else {
    return true;
  }
}

function getPlanDetails(store: any, total: number, feature: string) {
  if (isUserAdmin(store)) {
    return "";
  }

  const limit = getPlanLimit(store, feature);

  if (limit) {
    return `${total}/${limit}`;
  } else return "";
}

function getPlanLimit(store: any, feature: string) {
  if (isUserAdmin(store)) {
    return null;
  }

  if (user(store)?.plan_id) {
    if (isFreePlan(store)) {
      return getPlanFeatureLimit("standard", feature);
    }
    if (isPremiumPlan(store)) {
      return getPlanFeatureLimit("premium", feature);
    }
    return null;
  } else {
    return null;
  }
}

function getPlanFeatureLimit(planName: string, featureName: string) {
  return PLAN_DETAILS.find((plan) => plan.plan === planName)?.value.find((feature) => feature.feature === featureName)?.limit ?? 0;
}

async function getPlanData(planID: number) {
  const result: any = await request({}, `plans/${planID}`, "GET");
  if (result.ok) {
    return result.data;
  }
  return null;
}

async function getPlans() {
  const result: any = await request({}, `plans`, "GET");
  if (result.ok) {
    return result?.data;
  }
  return null;
}

function getPlanFeatureCode(planName: string) {
  return PLAN_DETAILS.find((plan) => plan.plan === planName)?.id ?? 0;
}

function getPlan(store: any) {
  if (user(store)?.plan_id) {
    if (isPremiumPlan(store)) {
      return "Premium";
    } else if (isEnterpriseOrPlusPlan(store)) {
      return "Enterprise";
    }
  }
  return "Standard";
}

function getPlanFromID(planID: number) {
  if (planID === 1) {
    return "standard";
  } else if (planID === 2) {
    return "premium";
  } else if (planID === 3) {
    return "enterprise";
  } else if (planID === 4) {
    return "enterprise-plus";
  }

  return "";
}

function validateEmptyField(value: string, fieldName: string): string {
  if (!value) {
    return `Please enter your ${fieldName}`;
  }
  return "";
}

function validateEmail(email: string): string {
  if (!email) {
    return "Please enter your email";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    return "Please enter a valid email address";
  }
  return "";
}

function validatePassword(password: string): string {
  if (!password) {
    return "Please enter your password";
  } else if (password.length < 8) {
    return "Password must be at least 8 characters long";
  } else if (!/[A-Z]/.test(password)) {
    return "Password must contain at least one capital letter";
  } else if (!/[\W_]/.test(password)) {
    return "Password must contain at least one special character";
  }
  return "";
}

function validateConfirmPassword(confirmPassword: string, password: string) {
  if (!confirmPassword) {
    return "Please confirm your password";
  } else if (confirmPassword !== password) {
    return "Passwords do not match";
  }
  return "";
}

function validatePhone(phone: string): string {
  if (!phone) {
    return "Please enter your phone number";
  } else if (!/^\d{12}$/.test(phone)) {
    return "Please enter a valid 10-digit phone number";
  }
  return "";
}

function getStatusClass(status: string) {
  let className = "";
  if (status === "approved") {
    className = className + "font-bold text-green-500";
  }
  return className;
}

function getStatusClassName(status: string): string {
  if (!status) {
    return "";
  }
  return [
    "success",
    "complete",
    "approved",
    "active",
    "won",
    "in_progress",
    "paid",
    "premium",
    "enterprise",
    "enterprise-plus",
    "vacant"
  ].indexOf(status) >= 0
    ? "status-badge green-status"
    : [
          "rent_due",
          "expired",
          "balance_due",
          "eviction",
          "suspended",
          "archived",
          "lost",
          "failed",
          "overdue",
          "cancelled",
          "test",
          "closed",
          "under_maintenance",
          "sold"
        ].indexOf(status) >= 0
      ? "status-badge red-status"
      : "status-badge blue-status";
}

function getPriorityClassName(priority: string): string {
  return ["low"].indexOf(priority) >= 0
    ? "status-badge green-status"
    : ["urgent", "high"].indexOf(priority) >= 0
      ? "status-badge red-status"
      : "status-badge blue-status";
}

function getPlanClass(plan: string) {
  let className = "";
  if (plan === "standard") {
    className = "font-bold text-red-500";
  } else {
    className = "font-bold text-green-500";
  }
  return className;
}

function getRelativeDateTime(dateTimeString: any) {
  // Parse the server time
  let dateTime = moment.utc(dateTimeString);
  dateTime = dateTime.local();

  // Get the current local time
  const now = moment();

  // Calculate the difference in seconds
  const diffInSeconds = now.diff(dateTime, "seconds");

  // Determine the appropriate format based on the difference in seconds
  if (diffInSeconds < 10) {
    return "A few seconds ago";
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
}

async function fetchProfilePicture(store: any) {
  const result: any = await request(store, `documents/category/${user(store)?.id}?category=profile_picture`, "GET");
  if (result.ok) {
    return result.data;
  }
}

// Payments

async function onAuthURLCreateAndOpen(
  store: any,
  subscribeArgs: { user_id: number; first_name: string; last_name: string; email: string; plan_id: number; type: string },
  setError: any
) {
  // console.log(subscribeArgs);
  const result: any = await request(store, "pay", "POST", subscribeArgs);
  // console.log(result);
  if (result.ok) {
    if (result.data.authorization_url) {
      window.location.href = result.data.authorization_url;
    }
  } else {
    if (setError) {
      setError(result?.error);
    }
  }
}

function getCurrencyAmount(amount: number) {
  const currency = "R";
  return amount ? `${currency} ${amount}` : "";
}

function getCurrencySymbol() {
  const currencySymbol = "ZAR";
  return currencySymbol;
}

export {
  getError,
  generateErrorMessage,
  generateMessage,
  isUserAccountAdmin,
  isUserAccountUser,
  isUserTenant,
  isUserAdmin,
  isUserSuperAdmin,
  isUserSuperAdminOrAdmin,
  user,
  profilePicture,
  humanFormatDate,
  humanFormatDateWithDay,
  humanFormatDateTime,
  getMonth,
  getCurrentMonthEndDate,
  titleCase,
  request,
  clone,
  showToast,
  generateID,
  isFreePlan,
  isPremiumPlan,
  isEnterpriseOrPlusPlan,
  hasReachedPropertiesLimit,
  hasReachedPropertyGroupLimit,
  getPlanDetails,
  getPlanFeatureLimit,
  getPlanData,
  getPlans,
  getPlanFeatureCode,
  getPlan,
  getPlanFromID,
  validateEmail,
  validatePhone,
  validatePassword,
  validateConfirmPassword,
  validateEmptyField,
  getStatusClass,
  getStatusClassName,
  getPriorityClassName,
  getPlanClass,
  getRelativeDateTime,
  fetchProfilePicture,
  onAuthURLCreateAndOpen,
  getCurrencyAmount,
  getCurrencySymbol
  // unreadNotificationCount
};
