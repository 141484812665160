import { SizeProp } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../../utils/icons";
import { MouseEventHandler, useState } from "react";

interface IProps {
  name: string;
  size?: SizeProp;
  className?: string;
  canRotate?: boolean;
  onClick?: MouseEventHandler<SVGSVGElement>;
}
function Icon(props: IProps) {
  const { name, size, className, canRotate } = props;
  const iconSize: SizeProp = "sm";
  const [isRotating, setIsRotating] = useState<boolean>(false);

  function rotate() {
    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 1000); // Stops the rotation after 1 second
  }

  function render() {
    // const optionalProps = canRotate ? { onClick: () => rotate() } : {};
    return (
      <FontAwesomeIcon
        className={`${isRotating ? "rotate-180 transform duration-200" : " "} ${className ? className : "text-primary"}`}
        icon={icons[name]}
        size={size ? size : iconSize}
        onClick={(event) => {
          if (props.onClick) {
            props.onClick(event);
          }
          if (canRotate) {
            rotate();
          }
        }}
      />
    );
  }
  return render();
}

export default Icon;
